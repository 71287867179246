import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Acronists are trained wielders of `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminous Magic`}</a>{`. As it takes a great deal of talent and dedication to develop strong `}<a parentName="p" {...{
        "href": "/Conviction",
        "title": "Conviction"
      }}>{`Convictions`}</a>{`, many Acronists studied for many years to become proficient at Magic.`}</p>
    <h2>{`Pure Acronist`}</h2>
    <p>{`Incredibly rare, Pure Acronists manifest `}<a parentName="p" {...{
        "href": "/Conviction",
        "title": "Conviction"
      }}>{`Convictions`}</a>{` purely from their self-belief, without the aid of any `}<a parentName="p" {...{
        "href": "/Primal%20Spirits",
        "title": "Primal Spirits"
      }}>{`Primal Spirit`}</a>{`.`}</p>
    <h2>{`Primal Acronist`}</h2>
    <p>{`More common bar far, especially in the Eastern lands of `}<a parentName="p" {...{
        "href": "/Selar",
        "title": "Selar"
      }}>{`Selar`}</a>{` and `}<a parentName="p" {...{
        "href": "/Edelun",
        "title": "Edelun"
      }}>{`Edelun`}</a>{`, Acronists bind themselves to a `}<a parentName="p" {...{
        "href": "/Primal%20Spirits",
        "title": "Primal Spirits"
      }}>{`Primal Spirit`}</a>{`, leveraging the Spirit's connection to `}<a parentName="p" {...{
        "href": "/The%20Luminary",
        "title": "The Luminary"
      }}>{`The Luminary`}</a>{` to more easily manifest Convictions and prevent the worst `}<a parentName="p" {...{
        "href": "/Backlash",
        "title": "Backlash"
      }}>{`Backlash`}</a>{`.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "left center square"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/1e7bb9c03ef059982928509f75c844d8/0abdd/Morne_young_male_mage_Magic_ritual_binding_the_spirit_of_air_430bf548-3c2e-44dc-be59-99a967658a7d.png",
            "srcSet": ["/static/1e7bb9c03ef059982928509f75c844d8/5ff7e/Morne_young_male_mage_Magic_ritual_binding_the_spirit_of_air_430bf548-3c2e-44dc-be59-99a967658a7d.png 375w", "/static/1e7bb9c03ef059982928509f75c844d8/0abdd/Morne_young_male_mage_Magic_ritual_binding_the_spirit_of_air_430bf548-3c2e-44dc-be59-99a967658a7d.png 510w"],
            "width": "400px",
            "className": "left center square"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Young Acronist attemps to bind the Primal Air`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <h3>{`The Exchange`}</h3>
    <p>{`To bind a `}<a parentName="p" {...{
        "href": "/Primal%20Spirits",
        "title": "Primal Spirits"
      }}>{`Primal Spirit`}</a>{`, an Acronist must perform `}<a parentName="p" {...{
        "href": "/The%20Exchange",
        "title": "The Exchange"
      }}>{`The Exchange`}</a>{`, a ritual that varies wildly in difficulty and implementation depending on the Spirit and Acronist involved. This Exchange is sometimes permanent, but not always, and some bindings even need to be maintained through consistent ritual by the Acronist.`}</p>
    <h3>{`The Aspects`}</h3>
    <p>{`Each `}<a parentName="p" {...{
        "href": "/Primal%20Spirits",
        "title": "Primal Spirits"
      }}>{`Primal Spirit`}</a>{` is tied to three `}<a parentName="p" {...{
        "href": "/Primal%20Aspects",
        "title": "Primal Aspects"
      }}>{`Primal Aspects`}</a>{`, and each Spirit shares two of their Aspects with an adjacent spirit.`}</p>
    <h3>{`Types of Primal Acronists`}</h3>
    <h4>{`Furybearers`}</h4>
    <p><strong parentName="p">{`Furybearers`}</strong>{` are Acronists who have bonded with the Primal Spirit of Fire. They are granted access to the Aspects of `}<em parentName="p">{`Energy`}</em>{`, `}<em parentName="p">{`Emotion`}</em>{`, and `}<em parentName="p">{`Memory`}</em>{`.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/493a3936ad8a697bbbfa3f6c19b33c3d/a016c/Morne_tinkerer_wearing_an_apron_sitting_at_a_table_working_fant_993bf983-68ec-46ea-a755-d070448b0b38.png",
            "srcSet": ["/static/493a3936ad8a697bbbfa3f6c19b33c3d/5ff7e/Morne_tinkerer_wearing_an_apron_sitting_at_a_table_working_fant_993bf983-68ec-46ea-a755-d070448b0b38.png 375w", "/static/493a3936ad8a697bbbfa3f6c19b33c3d/1d69c/Morne_tinkerer_wearing_an_apron_sitting_at_a_table_working_fant_993bf983-68ec-46ea-a755-d070448b0b38.png 750w", "/static/493a3936ad8a697bbbfa3f6c19b33c3d/a016c/Morne_tinkerer_wearing_an_apron_sitting_at_a_table_working_fant_993bf983-68ec-46ea-a755-d070448b0b38.png 762w"],
            "width": "400px",
            "className": "right center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Stonesinger`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <h4>{`Stonesingers`}</h4>
    <p><strong parentName="p">{`Stonesingers`}</strong>{` are Acronists who have bonded with the Primal Spirit of Earth. They are granted access to the Aspects of `}<em parentName="p">{`Memory`}</em>{`, `}<em parentName="p">{`Material`}</em>{`, and `}<em parentName="p">{`Gravity`}</em>{`.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "left center vertical"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/5f8533fcb436aeda7c33ce6af6d205b2/92e00/Morne_gravity_mage_flying_through_the_city_fantasy_art_action_p_8c7e6e03-62c7-4596-a650-fbc5f15b6769.png",
            "srcSet": ["/static/5f8533fcb436aeda7c33ce6af6d205b2/5ff7e/Morne_gravity_mage_flying_through_the_city_fantasy_art_action_p_8c7e6e03-62c7-4596-a650-fbc5f15b6769.png 375w", "/static/5f8533fcb436aeda7c33ce6af6d205b2/92e00/Morne_gravity_mage_flying_through_the_city_fantasy_art_action_p_8c7e6e03-62c7-4596-a650-fbc5f15b6769.png 511w"],
            "width": "250px",
            "className": "left center vertical"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Farseeker`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <h4>{`Farseeker`}</h4>
    <p><strong parentName="p">{`Farseeker`}</strong>{`s are Acronists who have bonded with the Primal Spirit of Air. They are granted access to the Aspects of `}<em parentName="p">{`Gravity`}</em>{`, `}<em parentName="p">{`Space`}</em>{`, and `}<em parentName="p">{`Illusion`}</em>{`.`}</p>
    <h4>{`Vastcaller`}</h4>
    <p><strong parentName="p">{`Vastcaller`}</strong>{` are Acronists who have bonded with the Primal Spirit of Water. They are granted access to the Aspects of `}<em parentName="p">{`Illusion`}</em>{`, `}<em parentName="p">{`Force`}</em>{`, and `}<em parentName="p">{`Life`}</em>{`.`}</p>
    <h4>{`Boneforger`}</h4>
    <p><strong parentName="p">{`Boneforger`}</strong>{` are Acronists who have bonded with the Primal Spirit of Bone. They are granted access to the Aspects of `}<em parentName="p">{`Life`}</em>{`, `}<em parentName="p">{`Growth`}</em>{`, and `}<em parentName="p">{`Shape`}</em>{`.`}</p>
    <h4>{`Soulrender`}</h4>
    <p><strong parentName="p">{`Soulrender`}</strong>{` are Acronists who have bonded with the Primal Spirit of Soul. They are granted access to the Aspects of `}<em parentName="p">{`Shape`}</em>{`, `}<em parentName="p">{`Will`}</em>{`, and `}<em parentName="p">{`Emotion`}</em>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      